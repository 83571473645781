import { UmbracoTypes } from '~/lib/data-contract';

/**
 * Get image focal points from the umbraco
 */

export default function getFocalPoint(image: UmbracoTypes.IImageMedia | undefined) {
    return image?.focalPoint
        ? `${image?.focalPoint.left * 100}% ${image?.focalPoint.top * 100}%`
        : 'unset';
}
