import PlayIcon from '~/icons/iconography/play.svg';
import { Text } from '~/shared/components/Text';
import { StyledPlayButton } from './styled';

export type PlayButtonProps = {
    playing: boolean;
    onClick: () => void;
    showText?: boolean;
};

export const PlayButton = ({ playing, onClick, showText = true }: PlayButtonProps) => {
    return (
        <StyledPlayButton playing={playing} onClick={onClick}>
            <PlayIcon />
            {showText && <Text variant="display4">Afspil video</Text>}
        </StyledPlayButton>
    );
};

export default PlayButton;
