import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';
import { StyledText } from '~/shared/components/Text';
import { StyledResetButton } from '~/shared/styles/Button';
import { PlayButtonProps } from './Video';

interface StyledVideoContainerProps {
    playing?: boolean;
    cover?: boolean;
}

export const StyledVideoAspect = styled.div({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    width: '100%',
    iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
});

export const StyledVideoOverlay = styled.div<StyledVideoContainerProps>(
    {
        top: 0,
        left: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
        transition: 'opacity 0.25s',
        cursor: 'pointer',
        backgroundColor: '#000',
    },
    ifProp('playing', () => ({
        opacity: 0,
        pointerEvents: 'none',
    }))
);

export const StyledVideoContainer = styled.div<StyledVideoContainerProps>(
    {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    ifProp('cover', {
        height: '100%',
    }),
    ifNotProp('playing', {
        [`${StyledVideoOverlay}`]: {
            opacity: 0.3,
        },
        '&:hover': {
            [`${StyledVideoOverlay}`]: {
                opacity: 0.5,
            },
        },
    })
);

export const StyledPlayButton = styled(StyledResetButton)<PlayButtonProps>(
    ({ theme }) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        transformOrigin: 'center',
        transition: 'opacity 0.25s, transform 0.1s ease-in-out',
        zIndex: 2,
        color: theme.colors.offWhite,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        alignItems: 'center',
        svg: {
            transition: 'transform 0.25s ease-in-out',
            fontSize: '60px',
            display: 'block',
            transform: 'none',
        },
        path: {
            stroke: theme.colors.offWhite,
            strokeWidth: '1px',
        },
        [`${StyledText}`]: {
            color: theme.colors.offWhite,
        },
        [`${StyledVideoContainer}:hover & svg`]: {
            transform: 'scale(1.15)',
        },
    }),
    ifProp('playing', () => ({
        opacity: 0,
        pointerEvents: 'none',
        transform: 'translate(-50%, -48%)',
    }))
);
