import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { breakpoints } from '$theme';
import { UmbracoTypes } from '~/lib/data-contract';
import { StyledAlignContent } from '../../shared/AlignContent/styled';
import { M75MediaTextProps } from './M75MediaText';

type StyledM75ContentProps = {
    backgroundColor: M75MediaTextProps['backgroundColor'];
    hasGap: M75MediaTextProps['hasGap'];
    maxHeight: M75MediaTextProps['maxHeight'];
    mediaAlignment: M75MediaTextProps['mediaHorizontalAlignment'];
    mediaSize: M75MediaTextProps['mediaSize'];
    mediaType: 'video' | 'image';
};

export const StyledM75 = styled.div({
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
});

export const StyledMediaArea = styled.div<{ mediaType: 'video' | 'image' }>(
    {
        gridArea: 'media',
        position: 'relative',
        width: '100%',
    },
    ifProp(
        { mediaType: 'image' },
        {
            [breakpoints.xs]: {
                aspectRatio: '1.35',
            },
        },
        {
            aspectRatio: '16/9',
        },
    ),
);

export const StyledM75Content = styled.div<StyledM75ContentProps>(
    ({ mediaAlignment, backgroundColor, hasGap, maxHeight, mediaType, theme }) => ({
        height: 'auto',
        boxSizing: 'border-box',
        width: '100%',
        margin: '0 auto',
        gap: hasGap ? theme.space[2] : 0,
        display: 'flex',
        flexDirection: mediaAlignment === 'right' ? 'column' : 'column-reverse',
        [breakpoints.sm]: {
            display: 'grid',
            gridAutoFlow: 'column',
            gridTemplateAreas: mediaAlignment === 'right' ? '"content media"' : '"media content"',
            aspectRatio: mediaType === 'video' ? '3.75' : '',
            minHeight:
                mediaType === 'video'
                    ? ''
                    : maxHeight
                      ? UmbracoTypes.M75MediaModuleMaxHeight[
                            maxHeight as unknown as keyof typeof UmbracoTypes.M75MediaModuleMaxHeight
                        ]
                      : UmbracoTypes.M75MediaModuleMaxHeight.Medium,
            maxHeight:
                mediaType === 'video'
                    ? maxHeight
                        ? UmbracoTypes.M75MediaModuleMaxHeight[
                              maxHeight as unknown as keyof typeof UmbracoTypes.M75MediaModuleMaxHeight
                          ]
                        : UmbracoTypes.M75MediaModuleMaxHeight.Medium
                    : '',
        },
        [`${StyledAlignContent}`]: {
            backgroundColor,
            padding: theme.space['4'],
            [breakpoints.xs]: {
                paddingTop: backgroundColor ? theme.space['7'] : theme.space['5'],
                paddingBottom: backgroundColor ? theme.space['7'] : theme.space['5'],
            },
            [breakpoints.md]: {
                padding: theme.space['6'],
            },
        },
    }),

    switchProp(
        'mediaSize',
        {
            default: ({ mediaAlignment, mediaType }) => ({
                [breakpoints.sm]: {
                    gridAutoColumns:
                        mediaType === 'video'
                            ? mediaAlignment === 'right'
                                ? '1fr auto'
                                : 'auto 1fr'
                            : '1fr 1fr',
                },
            }),
            small: ({ mediaAlignment }) => ({
                gridAutoColumns: mediaAlignment === 'right' ? '1fr 30%' : '30% 1fr',
            }),
        },
        'default',
    ),

    // Ensures content aligns with the edge
    // if there is no background color
    ifProp({ backgroundColor: 'none' }, ({ mediaAlignment }) => ({
        [`${StyledAlignContent}`]: {
            paddingLeft: mediaAlignment === 'left' ? undefined : 0,
            paddingRight: mediaAlignment === 'right' ? undefined : 0,
        },
    })),
);
